.logo {
  left: 0;
  position: absolute;
  top: 0;
}

.logo.default {
  height: 40px;
}

.logo.small {
  height: 32px;
  width: 176px;
}

.logo.default.icon {
  width: 47px;
}

.logo.default.logotype {
  width: 220px;
}
