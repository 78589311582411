:root {
  --black-100: rgba(25, 25, 25, 1);
  --headings-h2-font-family: "HKGrotesk-SemiBold", Helvetica;
  --headings-h2-font-size: 48px;
  --headings-h2-font-style: normal;
  --headings-h2-font-weight: 600;
  --headings-h2-letter-spacing: -0.72px;
  --headings-h2-line-height: 120.00000476837158%;
  --headings-h3-font-family: "HKGrotesk-SemiBold", Helvetica;
  --headings-h3-font-size: 42px;
  --headings-h3-font-style: normal;
  --headings-h3-font-weight: 600;
  --headings-h3-letter-spacing: -0.63px;
  --headings-h3-line-height: 132.00000524520874%;
  --headings-h5-font-family: "HKGrotesk-Bold", Helvetica;
  --headings-h5-font-size: 28px;
  --headings-h5-font-style: normal;
  --headings-h5-font-weight: 700;
  --headings-h5-letter-spacing: -0.14px;
  --headings-h5-line-height: 132.00000524520874%;
  --headings-h6-font-family: "HKGrotesk-Bold", Helvetica;
  --headings-h6-font-size: 24px;
  --headings-h6-font-style: normal;
  --headings-h6-font-weight: 700;
  --headings-h6-letter-spacing: 0px;
  --headings-h6-line-height: 132.00000524520874%;
  --label-color-dark-primary: rgba(255, 255, 255, 1);
  --label-color-light-primary: rgba(0, 0, 0, 1);
  --large-default-body-font-family: "SFPro Text-Regular", Helvetica;
  --large-default-body-font-size: 17px;
  --large-default-body-font-style: normal;
  --large-default-body-font-weight: 400;
  --large-default-body-letter-spacing: -0.40799999237060547px;
  --large-default-body-line-height: 22px;
  --large-default-headline-font-family: "SFPro Text-Semibold", Helvetica;
  --large-default-headline-font-size: 17px;
  --large-default-headline-font-style: normal;
  --large-default-headline-font-weight: 400;
  --large-default-headline-letter-spacing: -0.40799999237060547px;
  --large-default-headline-line-height: 22px;
  --neutral-0: rgba(255, 255, 255, 1);
  --neutral-100: rgba(245, 246, 244, 1);
  --neutral-500: rgba(117, 117, 117, 1);
  --neutral-600: rgba(94, 94, 94, 1);
  --neutral-700: rgba(71, 71, 71, 1);
  --neutral-800: rgba(48, 48, 48, 1);
  --neutral-900: rgba(25, 25, 25, 1);
  --paragraph-regular-font-family: "Manrope-Medium", Helvetica;
  --paragraph-regular-font-size: 16px;
  --paragraph-regular-font-style: normal;
  --paragraph-regular-font-weight: 500;
  --paragraph-regular-letter-spacing: 0px;
  --paragraph-regular-line-height: 139.9999976158142%;
  --primary-01200: rgba(250, 255, 233, 1);
  --primary-01600: rgba(206, 253, 30, 1);
  --primary-02-100: rgba(230, 251, 242, 1);
  --primary-02600: rgba(0, 211, 127, 1);
  --primary-03-200: rgba(231, 237, 238, 1);
  --primary-03-900: rgba(6, 32, 34, 1);
  --primary-03600: rgba(16, 79, 85, 1);
  --subtext-bold-font-family: "Manrope-Bold", Helvetica;
  --subtext-bold-font-size: 20px;
  --subtext-bold-font-style: normal;
  --subtext-bold-font-weight: 700;
  --subtext-bold-letter-spacing: 0px;
  --subtext-bold-line-height: 132.00000524520874%;
  --subtext-regular-font-family: "Manrope-Medium", Helvetica;
  --subtext-regular-font-size: 20px;
  --subtext-regular-font-style: normal;
  --subtext-regular-font-weight: 500;
  --subtext-regular-letter-spacing: 0px;
  --subtext-regular-line-height: 132.00000524520874%;
  --subtitle-bold-font-family: "Manrope-Bold", Helvetica;
  --subtitle-bold-font-size: 24px;
  --subtitle-bold-font-style: normal;
  --subtitle-bold-font-weight: 700;
  --subtitle-bold-letter-spacing: 0px;
  --subtitle-bold-line-height: 132.00000524520874%;
  --subtitle-regular-font-family: "Manrope-Medium", Helvetica;
  --subtitle-regular-font-size: 24px;
  --subtitle-regular-font-style: normal;
  --subtitle-regular-font-weight: 500;
  --subtitle-regular-letter-spacing: 0px;
  --subtitle-regular-line-height: 139.9999976158142%;
  --systemblue-light: rgba(0, 122, 255, 1);
  --title-medium-font-family: "HKGrotesk-SemiBold", Helvetica;
  --title-medium-font-size: 72px;
  --title-medium-font-style: normal;
  --title-medium-font-weight: 600;
  --title-medium-letter-spacing: -1.8px;
  --title-medium-line-height: 120.00000476837158%;
}
