.element {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}

.element .div {
  background-color: #ffffff;
  height: 21926px;
  position: relative;
  width: 1920px;
}

.element .overlap {
  height: 1326px;
  left: 0;
  position: absolute;
  top: 7709px;
  width: 1920px;
}

.element .frame {
  background-color: #ffffff;
  height: 1326px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .overlap-group-wrapper {
  border: 8.77px solid;
  border-color: #dadada;
  border-radius: 23.38px;
  box-shadow: -10px 2px 64px #0000001f;
  height: 675px;
  left: -204px;
  overflow: hidden;
  position: absolute;
  top: 606px;
  width: 1070px;
}

.element .overlap-group {
  background: linear-gradient(180deg, rgb(25, 25, 25) 0%, rgb(16, 79, 85) 100%);
  height: 658px;
  position: relative;
  width: 1052px;
}

.element .frame-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 46.76px;
  justify-content: center;
  left: 315px;
  position: absolute;
  top: 159px;
}

.element .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 35.07px;
  justify-content: center;
  position: relative;
}

.element .text-wrapper {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 58.4px;
  font-weight: 800;
  letter-spacing: -0.88px;
  line-height: 64.3px;
  margin-top: -0.73px;
  position: relative;
  text-align: center;
  width: 422.26px;
}

.element .so-why-only-few-make {
  flex: 0 0 auto;
  position: relative;
  width: 345.82px;
}

.element .CTA {
  all: unset;
  align-items: center;
  background: linear-gradient(180deg, rgb(217, 245, 81) 0%, rgb(0, 211, 127) 100%);
  border-radius: 2.92px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.31px;
  justify-content: center;
  padding: 17.53px 29.22px;
  position: relative;
}

.element .text-wrapper-2 {
  color: var(--neutral-900);
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23.1px;
  margin-top: -0.73px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.31px;
  justify-content: center;
  left: 0;
  padding: 17.53px 75.98px;
  position: absolute;
  top: 0;
  width: 1052px;
}

.element .logo-instance {
  height: 29.22px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 41.84px !important;
}

.element .overlap-wrapper {
  border: 12px solid;
  border-color: #dadada;
  border-radius: 32px;
  box-shadow: -10px 2px 64px #0000001f;
  height: 894px;
  left: 926px;
  overflow: hidden;
  position: absolute;
  top: 93px;
  width: 1200px;
}

.element .overlap-2 {
  background-color: #191919;
  height: 870px;
  position: relative;
  width: 1176px;
}

.element .frame-4 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 39.2px;
  left: 261px;
  position: absolute;
  top: 568px;
}

.element .this-takes-years-to {
  flex: 0 0 auto;
  position: relative;
  width: 620.34px;
}

.element .div-wrapper {
  align-items: center;
  background-color: var(--primary-02600);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.17px;
  justify-content: center;
  padding: 21.23px 32.67px;
  position: relative;
}

.element .text-wrapper-3 {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 19.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-top: -0.82px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .overlap-3 {
  border-radius: 3.27px;
  height: 244px;
  left: 492px;
  position: absolute;
  top: 265px;
  width: 190px;
}

.element .vector {
  height: 141px;
  left: 27px;
  position: absolute;
  top: 86px;
  width: 96px;
}

.element .rectangle {
  background: linear-gradient(180deg, rgb(25, 25, 25) 0%, rgb(16, 79, 85) 100%);
  border-radius: 3.27px;
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  width: 190px;
}

.element .group {
  height: 126px;
  left: 23px;
  position: absolute;
  top: 99px;
  width: 155px;
}

.element .efficient-investment {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 44px;
  left: 11px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 23px;
  width: 167px;
}

.element .logo-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.17px;
  justify-content: center;
  left: 0;
  padding: 19.6px 84.93px;
  position: absolute;
  top: 0;
  width: 1176px;
}

.element .design-component-instance-node {
  height: 32.67px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 179.82px !important;
}

.element .to-be-a-consistently {
  color: var(--neutral-100);
  font-family: "HK Grotesk-Regular", Helvetica;
  font-size: 49px;
  font-weight: 400;
  height: 118px;
  left: 225px;
  letter-spacing: -1.47px;
  line-height: 58.8px;
  position: absolute;
  text-align: center;
  top: 110px;
  width: 760px;
}

.element .overlap-4 {
  background: linear-gradient(180deg, rgb(25, 25, 25) 0%, rgb(16, 79, 85) 100%);
  background-image: url(../../../static/img/mask-group-4.png);
  background-size: 100% 100%;
  border-radius: 3.27px;
  height: 244px;
  left: 696px;
  position: absolute;
  top: 265px;
  width: 192px;
}

.element .text-wrapper-4 {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 44px;
  left: 22px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 23px;
  width: 147px;
}

.element .overlap-group-wrapper-2 {
  height: 244px;
  left: 288px;
  position: absolute;
  top: 265px;
  width: 191px;
}

.element .overlap-group-2 {
  border-radius: 4.9px;
  height: 244px;
  position: relative;
}

.element .rectangle-2 {
  background: linear-gradient(180deg, rgb(25, 25, 25) 0%, rgb(16, 79, 85) 100%);
  border-radius: 4.9px;
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 191px;
}

.element .unwavering-trading {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 44px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 22px;
  width: 158px;
}

.element .mask-group {
  height: 189px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 191px;
}

.element .img {
  height: 244px;
  left: 84px;
  position: absolute;
  top: 266px;
  width: 191px;
}

.element .div-2 {
  height: 244px;
  left: 901px;
  position: absolute;
  top: 265px;
  width: 190px;
}

.element .overlap-5 {
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  width: 171px;
}

.element .rectangle-3 {
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  width: 81px;
}

.element .text-wrapper-5 {
  color: var(--neutral-0);
  font-family: "Manrope", Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 44px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 22px;
  width: 151px;
}

.element .mask-group-2 {
  height: 167px;
  left: 0;
  position: absolute;
  top: 77px;
  width: 81px;
}

.element .group-2 {
  height: 26px;
  left: 12px;
  position: absolute;
  top: 176px;
  width: 46px;
}

.element .group-3 {
  height: 33px;
  left: -1699px;
  position: absolute;
  top: -6462px;
  width: 57px;
}

.element .seamlessly {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 56px;
  font-weight: 500;
  height: 140px;
  left: 120px;
  letter-spacing: -1.12px;
  line-height: normal;
  position: absolute;
  top: 120px;
  width: 703px;
}

.element .growth-user-centric {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 136px;
  left: 120px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 309px;
  width: 663px;
}

.element .frame-5 {
  align-items: flex-end;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 1584px;
  position: absolute;
  top: 120px;
}

.element .text-wrapper-6 {
  color: #484848;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.element .text-wrapper-7 {
  color: #484848;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.element .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 120px;
  position: absolute;
  top: 120px;
}

.element .text-wrapper-8 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .intelligent {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 112px;
  position: relative;
  width: fit-content;
}

.element .group-4 {
  height: 120px;
  left: 120px;
  position: absolute;
  top: 463px;
  width: 350px;
}

.element .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 64px;
}

.element .UIX {
  height: 56px;
  position: relative;
  width: 183px;
}

.element .frame-wrapper {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.element .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.element .group-5 {
  background-color: #f8f8f8;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.element .overlap-group-3 {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.element .line {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .line-2 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.element .text-wrapper-9 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .UIX-2 {
  height: 56px;
  position: relative;
  width: 149px;
}

.element .text-wrapper-10 {
  color: #5c45cc;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .hero {
  height: 840px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 653px;
  width: 1920px;
}

.element .element-wrapper {
  background-color: #00d37f;
  height: 888px;
  position: relative;
}

.element .img-2 {
  height: 784px;
  left: 492px;
  position: absolute;
  top: 56px;
  width: 936px;
}

.element .the-challenge {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 0;
  padding: 64px 0px 80px;
  position: absolute;
  top: 1493px;
  width: 1920px;
}

.element .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
}

.element .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.element .text-wrapper-11 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .let-s-face-it-many {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 33.6px;
  position: relative;
  width: 1507.98px;
}

.element .span {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 33.6px;
}

.element .text-wrapper-12 {
  font-family: "Neue Haas Grotesk Text Pro-Bold", Helvetica;
  font-weight: 700;
}

.element .frame-11 {
  align-items: center;
  background-color: #00d37f;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: center;
  padding: 32px;
  position: relative;
}

.element .text-wrapper-13 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.24px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.element .headshot {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2361px;
  width: 1920px;
}

.element .branding {
  background-color: #ffffff;
  height: 2247px;
  left: 0;
  position: absolute;
  top: 3441px;
  width: 1920px;
}

.element .overlap-6 {
  background-color: #ffffff;
  height: 2247px;
  position: relative;
}

.element .text-wrapper-14 {
  color: #191919;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 56px;
  font-weight: 500;
  height: 70px;
  left: 119px;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  top: 110px;
}

.element .group-wrapper {
  background-color: #104f55;
  border-radius: 24px;
  height: 882px;
  left: 120px;
  overflow: hidden;
  position: absolute;
  top: 252px;
  width: 663px;
}

.element .group-6 {
  height: 274px;
  left: 173px;
  position: absolute;
  top: 304px;
  width: 319px;
}

.element .frame-12 {
  border-radius: 24px;
  height: 651px;
  left: 1233px;
  overflow: hidden;
  position: absolute;
  top: 1359px;
  width: 567px;
}

.element .overlap-7 {
  height: 1681px;
  position: relative;
  top: -799px;
  width: 760px;
}

.element .rectangle-4 {
  background-color: #d0b6fc;
  height: 882px;
  left: 0;
  position: absolute;
  top: 799px;
  width: 663px;
}

.element .mockup {
  box-shadow: 0px 72.17px 84.19px #9393935e;
  height: 1298px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 648px;
}

.element .overlap-8 {
  height: 1298px;
  position: relative;
}

.element .body-space-gray {
  height: 1298px;
  left: 0;
  position: absolute;
  top: 0;
  width: 648px;
}

.element .button-space-gray {
  height: 51px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 174px;
  width: 6px;
}

.element .rectangle-5 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.75px 0px 0.75px #00000026;
  height: 47px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 2px;
}

.element .union {
  height: 51px;
  left: -1319px;
  position: absolute;
  top: -2639px;
  width: 6px;
}

.element .button-space-gray-2 {
  height: 149px;
  left: 642px;
  overflow: hidden;
  position: absolute;
  top: 298px;
  transform: rotate(180deg);
  width: 6px;
}

.element .rectangle-6 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.75px 0px 0.75px #00000026;
  height: 144px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 2px;
}

.element .union-2 {
  height: 149px;
  left: 1961px;
  position: absolute;
  top: 2763px;
  transform: rotate(-180deg);
  width: 6px;
}

.element .button-space-gray-3 {
  height: 92px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 268px;
  width: 6px;
}

.element .rectangle-7 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: inset -0.75px 0px 0.75px #00000026;
  height: 87px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 2px;
}

.element .union-3 {
  height: 92px;
  left: -1319px;
  position: absolute;
  top: -2733px;
  width: 6px;
}

.element .button-space-gray-4 {
  height: 92px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 389px;
  width: 6px;
}

.element .union-4 {
  height: 92px;
  left: -1319px;
  position: absolute;
  top: -2854px;
  width: 6px;
}

.element .overlap-group-4 {
  background-image: url(../../../static/img/frame.svg);
  background-size: 100% 100%;
  height: 499px;
  left: 5px;
  position: absolute;
  top: 799px;
  width: 450px;
}

.element .shine {
  height: 499px;
  left: 0;
  position: absolute;
  top: 0;
  width: 450px;
}

.element .outer-ring {
  height: 498px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 450px;
}

.element .bands {
  height: 357px;
  left: 0;
  position: absolute;
  top: 0;
  width: 450px;
}

.element .base {
  height: 484px;
  left: 19px;
  position: absolute;
  top: 799px;
  width: 436px;
}

.element .display {
  height: 1267px;
  left: 20px;
  position: absolute;
  top: 15px;
  width: 609px;
}

.element .mask-group-3 {
  height: 459px;
  left: 23px;
  position: absolute;
  top: 784px;
  width: 413px;
}

.element .img-wrapper {
  background-image: url(../../../static/img/rectangle-677.svg);
  background-size: 100% 100%;
  border-radius: 24px;
  height: 916px;
  left: 839px;
  overflow: hidden;
  position: absolute;
  top: 374px;
  width: 961px;
}

.element .frame-13 {
  height: 168px;
  left: 98px;
  position: absolute;
  top: 374px;
  width: 765px;
}

.element .p {
  color: #282828;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 135px;
  left: 168px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 1165px;
  width: 555px;
}

.element .text-wrapper-15 {
  color: #282828;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 90px;
  left: 229px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 2046px;
  width: 855px;
}

.element .versatile-responsive {
  color: #282828;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 90px;
  left: 1275px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 2046px;
  width: 483px;
}

.element .text-wrapper-16 {
  color: #282828;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 90px;
  left: 909px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 251px;
  width: 824px;
}

.element .frame-14 {
  border-radius: 24px;
  height: 651px;
  left: 120px;
  position: absolute;
  top: 1359px;
  width: 1057px;
}

.element .overlap-9 {
  background-color: #f4fffa;
  border-radius: 24px;
  height: 651px;
  position: relative;
  width: 1058px;
}

.element .frame-15 {
  height: 527px;
  left: 153px;
  position: absolute;
  top: 62px;
  width: 768px;
}

.element .overlap-wrapper-2 {
  height: 941px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 5688px;
  width: 1920px;
}

.element .overlap-10 {
  background-color: #191919;
  height: 1080px;
  position: relative;
}

.element .rectangle-8 {
  background-color: #00d37f;
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .mackbooks {
  height: 941px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .rectangle-9 {
  background-color: #ffffff;
  height: 27px;
  left: 1019px;
  position: absolute;
  top: 135px;
  transform: rotate(13.52deg);
  width: 136px;
}

.element .group-7 {
  height: 48px;
  left: 1029px;
  position: absolute;
  top: 124px;
  width: 119px;
}

.element .overlap-wrapper-3 {
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 6629px;
  width: 1113px;
}

.element .overlap-11 {
  background-color: #191919;
  height: 1080px;
  position: relative;
  width: 1920px;
}

.element .overlap-group-wrapper-3 {
  height: 1080px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1113px;
}

.element .overlap-group-5 {
  background-color: #ef6461;
  height: 1663px;
  position: relative;
}

.element .frame-16 {
  height: 1080px;
  left: 125px;
  position: absolute;
  top: 0;
  width: 988px;
}

.element .overlap-wrapper-4 {
  height: 732px;
  left: 1113px;
  overflow: hidden;
  position: absolute;
  top: 6629px;
  width: 807px;
}

.element .overlap-12 {
  height: 1082px;
  left: -1034px;
  position: relative;
  width: 1920px;
}

.element .rectangle-10 {
  background-color: #191919;
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1920px;
}

.element .rectangle-11 {
  background-color: #d9f551;
  height: 732px;
  left: 1034px;
  position: absolute;
  top: 0;
  width: 807px;
}

.element .img-3 {
  height: 732px;
  left: 1101px;
  position: absolute;
  top: 0;
  width: 740px;
}

.element .basic-office {
  height: 730px;
  left: 1034px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 1px;
}

.element .ios-notification-wrapper {
  background-color: #1e2ede;
  height: 348px;
  left: 1113px;
  position: absolute;
  top: 7361px;
  width: 806px;
}

.element .ios-notification {
  align-items: center;
  background-color: #ffffff;
  border-radius: 13.94px;
  box-shadow: 0px 4px 96px #82828229;
  display: inline-flex;
  flex-direction: column;
  gap: 13.94px;
  left: 94px;
  padding: 20.91px 13.94px;
  position: relative;
  top: 82px;
}

.element .header-2 {
  height: 31.37px;
  position: relative;
  width: 590.83px;
}

.element .overlap-13 {
  height: 31px;
  position: relative;
  width: 595px;
}

.element .header-3 {
  height: 31px;
  left: 0;
  position: absolute;
  top: 0;
  width: 595px;
}

.element .overlap-group-6 {
  background-color: #757575;
  border-radius: 6.97px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 0;
  width: 31px;
}

.element .group-8 {
  background-color: #191919;
  border-radius: 6.97px;
  height: 31px;
}

.element .text-wrapper-17 {
  color: #1d1d1d;
  font-family: "Helvetica-Regular", Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  height: 21px;
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 319px;
}

.element .text-wrapper-18 {
  color: #1d1d1d;
  font-family: "Helvetica-Regular", Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  height: 21px;
  left: 387px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 3px;
  white-space: nowrap;
  width: 204px;
}

.element .logo-2 {
  height: 19px;
  left: 3px;
  position: absolute;
  top: 5px;
  width: 24px;
}

.element .meta-data {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.91px;
  overflow: hidden;
  position: relative;
}

.element .title-desc-pic {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 26.14px;
  overflow: hidden;
  position: relative;
}

.element .title-desc {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.97px;
  position: relative;
}

.element .div-wrapper-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17.43px;
  overflow: hidden;
  position: relative;
  width: 508.91px;
}

.element .title {
  color: #1d1d1d;
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 20.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.74px;
  position: relative;
  width: 508.91px;
}

.element .description {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17.43px;
  position: relative;
  width: 508.91px;
}

.element .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.97px;
  position: relative;
}

.element .description-2 {
  color: #1d1d1d;
  font-family: "Helvetica-Regular", Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.97px;
  position: relative;
}

.element .image {
  height: 12.07px;
  position: relative;
  width: 17.43px;
}

.element .roadie {
  border-radius: 6.97px;
  height: 55.77px;
  position: relative;
  width: 55.77px;
}

.element .text-wrapper-19 {
  align-self: stretch;
  color: #b1b1b1;
  font-family: "Helvetica-Regular", Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.74px;
  position: relative;
}

.element .frame-19 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 120px;
  position: absolute;
  top: 9099px;
  width: 1680px;
}

.element .text-wrapper-20 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 56px;
  font-weight: 500;
  letter-spacing: -1.12px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 582px;
}

.element .we-focused-on-every {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 44.8px;
  position: relative;
  width: 861px;
}

.element .web-wrapper {
  background-color: #e6fbf2;
  height: 6158px;
  left: 0;
  position: absolute;
  top: 9303px;
  width: 1920px;
}

.element .web {
  border: 12px solid;
  border-color: #104f55;
  border-radius: 32px;
  height: 5910px;
  left: 228px;
  overflow: hidden;
  position: relative;
  top: 117px;
  width: 1464px;
}

.element .overlap-14 {
  height: 3112px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.element .BG {
  background-color: #faffe9;
  height: 843px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.element .group-9 {
  height: 2955px;
  left: 0;
  position: absolute;
  top: 157px;
  width: 1440px;
}

.element .logo-instance-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 24px 104px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.element .logo-3 {
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 220.19px !important;
}

.element .context {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  left: 400px;
  position: absolute;
  top: 124px;
}

.element .title-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.element .text-wrapper-21 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(25, 25, 25) 40.67%, rgb(16, 79, 85) 100%);
  background-clip: text;
  color: transparent;
  font-family: var(--title-medium-font-family);
  font-size: var(--title-medium-font-size);
  font-style: var(--title-medium-font-style);
  font-weight: var(--title-medium-font-weight);
  letter-spacing: var(--title-medium-letter-spacing);
  line-height: var(--title-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-22 {
  color: var(--neutral-600);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  position: relative;
  text-align: center;
  width: 639px;
}

.element .CTA-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 526px;
}

.element .button {
  all: unset;
  background-color: var(--primary-01600);
  box-shadow: 0px 22px 63px #d2d2d208;
  box-sizing: border-box;
  height: 74px;
  position: relative;
  width: 275px;
}

.element .text-wrapper-23 {
  color: var(--neutral-900);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  height: 26px;
  left: 32px;
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  position: absolute;
  top: 23px;
  white-space: nowrap;
}

.element .coming-soon-on-web {
  color: transparent;
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.07px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-24 {
  color: #8c8c8c;
}

.element .text-wrapper-25 {
  color: #5c45cc;
}

.element .hero-image {
  height: 978px;
  left: 44px;
  position: absolute;
  top: 390px;
  width: 1351px;
}

.element .feature {
  height: 572px;
  left: 104px;
  position: absolute;
  top: 1823px;
  width: 1159px;
}

.element .image-2 {
  height: 572px;
  left: 0;
  position: absolute;
  top: 0;
  width: 599px;
}

.element .context-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 688px;
  overflow: hidden;
  position: absolute;
  top: 116px;
  width: 471px;
}

.element .text-wrapper-26 {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  margin-right: -22px;
  margin-top: -1px;
  position: relative;
  width: 493px;
}

.element .text-wrapper-27 {
  color: var(--primary-03-200);
  font-family: "Manrope", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  width: 471px;
}

.element .feature-2 {
  align-items: center;
  display: inline-flex;
  gap: 73px;
  left: 180px;
  position: absolute;
  top: 1179px;
}

.element .content {
  height: 352px;
  position: relative;
  width: 421px;
}

.element .CTA-3 {
  all: unset;
  align-items: center;
  background-color: var(--primary-01600);
  border-radius: 4px;
  box-shadow: 0px 22px 63px #d2d2d208;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 64px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 288px;
  width: 184px;
}

.element .text-wrapper-28 {
  color: var(--primary-03-900);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .content-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 421px;
}

.element .text-wrapper-29 {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .text-wrapper-30 {
  color: var(--primary-03-200);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: relative;
  width: 367px;
}

.element .image-3 {
  height: 594.24px;
  margin-right: -46.52px;
  margin-top: -22.24px;
  position: relative;
  width: 708.52px;
}

.element .group-10 {
  height: 572px;
  left: 104px;
  position: absolute;
  top: 2467px;
  width: 1336px;
}

.element .content-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 156px;
  width: 471px;
}

.element .efficient-investing {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  margin-right: -5px;
  margin-top: -1px;
  position: relative;
  width: 476px;
}

.element .image-4 {
  height: 572px;
  left: 584px;
  position: absolute;
  top: 0;
  width: 752px;
}

.element .frame-20 {
  align-items: center;
  background-color: var(--primary-01600);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 64px 96px;
  position: absolute;
  top: 3112px;
  width: 1440px;
}

.element .frame-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.element .text-wrapper-31 {
  color: #000000;
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.element .simulated {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 21px 36px 82px #90b1148f;
  height: 454px;
  position: relative;
  width: 389px;
}

.element .frame-23 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 24px;
  position: absolute;
  top: 34px;
}

.element .text-wrapper-32 {
  color: var(--neutral-800);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 341px;
}

.element .text-wrapper-33 {
  color: var(--neutral-700);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: relative;
  text-align: center;
  width: 341px;
}

.element .frame-24 {
  height: 234px;
  left: 24px;
  position: absolute;
  top: 197px;
  width: 341px;
}

.element .assisted {
  background-color: var(--primary-02-100);
  border-radius: 4px;
  height: 454px;
  position: relative;
  width: 389px;
}

.element .overlap-group-7 {
  background-color: #ffffff;
  border-radius: 12px;
  height: 454px;
  position: relative;
}

.element .text-wrapper-34 {
  color: var(--neutral-900);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 349px;
}

.element .text-wrapper-35 {
  color: var(--neutral-900);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: relative;
  text-align: center;
  width: 296px;
}

.element .automatic {
  background-color: #ffffff;
  border-radius: 12px;
  height: 454px;
  position: relative;
  width: 389px;
}

.element .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 24px;
  overflow: hidden;
  position: absolute;
  top: 34px;
  width: 341px;
}

.element .text-wrapper-36 {
  color: var(--neutral-900);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  margin-right: -8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 349px;
}

.element .text-wrapper-37 {
  color: var(--neutral-900);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: relative;
  text-align: center;
  width: 341px;
}

.element .group-11 {
  height: 400px;
  left: 0;
  position: absolute;
  top: 5486px;
  width: 1440px;
}

.element .frame-26 {
  align-items: center;
  background-color: var(--neutral-900);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 48px 96px;
  position: relative;
  width: 1440px;
}

.element .group-12 {
  height: 304px;
  position: relative;
  width: 1235.06px;
}

.element .group-13 {
  height: 114px;
  left: 0;
  position: absolute;
  top: 0;
  width: 605px;
}

.element .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 605px;
}

.element .text-wrapper-38 {
  color: var(--neutral-0);
  font-family: var(--subtitle-bold-font-family);
  font-size: var(--subtitle-bold-font-size);
  font-style: var(--subtitle-bold-font-style);
  font-weight: var(--subtitle-bold-font-weight);
  letter-spacing: var(--subtitle-bold-letter-spacing);
  line-height: var(--subtitle-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .apply-for-all-access {
  height: 66px;
  margin-right: -10.42px;
  overflow: hidden;
  position: relative;
  width: 615px;
}

.element .CTA-4 {
  border: 1px solid;
  border-color: var(--neutral-500);
  box-shadow: 0px 22px 63px #d2d2d208;
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 411px;
}

.element .join {
  align-items: center;
  background-color: var(--primary-02600);
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 411px;
  padding: 20px 24px;
  position: absolute;
  top: 0;
}

.element .text-wrapper-39 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-40 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  height: 26px;
  left: 32px;
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  position: absolute;
  top: 78px;
  white-space: nowrap;
}

.element .frame-28 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  position: absolute;
  top: 154px;
  width: 245px;
}

.element .group-14 {
  height: 26px;
  position: relative;
  width: 184px;
}

.element .text-wrapper-41 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  height: 26px;
  left: 0;
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element .support-moneyfactory-wrapper {
  height: 26px;
  margin-right: -1.61px;
  position: relative;
  width: 247px;
}

.element .support-moneyfactory {
  display: block;
  height: 20px;
  left: 1px;
  position: absolute;
  top: 6px;
  width: 242px;
}

.element .line-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 265px;
  width: 1233px;
}

.element .frame-29 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: space-between;
  left: 856px;
  position: absolute;
  top: 0;
  width: 376px;
}

.element .logo-4 {
  height: 44.36px !important;
  left: unset !important;
  margin-right: -0.21px !important;
  position: relative !important;
  top: unset !important;
  width: 244.21px !important;
}

.element .finaptis-consulting {
  color: var(--neutral-0);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: relative;
  text-align: right;
  width: 375px;
}

.element .instagram-linkedin {
  color: var(--neutral-0);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  height: 22px;
  left: 0;
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  position: absolute;
  top: 282px;
  width: 326px;
}

.element .section {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  left: 0;
  padding: 72px 96px 0px;
  position: absolute;
  top: 3789px;
  width: 1440px;
}

.element .group-15 {
  height: 436px;
  position: relative;
  width: 889px;
}

.element .frame-30 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 86px;
  position: absolute;
  top: 0;
}

.element .text-wrapper-42 {
  color: var(--neutral-900);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-43 {
  color: var(--neutral-900);
  font-family: var(--subtitle-regular-font-family);
  font-size: var(--subtitle-regular-font-size);
  font-style: var(--subtitle-regular-font-style);
  font-weight: var(--subtitle-regular-font-weight);
  letter-spacing: var(--subtitle-regular-letter-spacing);
  line-height: var(--subtitle-regular-line-height);
  position: relative;
  text-align: center;
  width: 747px;
}

.element .group-16 {
  height: 264px;
  left: 0;
  position: absolute;
  top: 172px;
  width: 889px;
}

.element .frame-31 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 138px;
}

.element .text-wrapper-44 {
  color: var(--neutral-900);
  font-family: var(--headings-h6-font-family);
  font-size: var(--headings-h6-font-size);
  font-style: var(--headings-h6-font-style);
  font-weight: var(--headings-h6-font-weight);
  letter-spacing: var(--headings-h6-letter-spacing);
  line-height: var(--headings-h6-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-45 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  position: relative;
  text-align: center;
  width: 207px;
}

.element .frame-32 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 298px;
  position: absolute;
  top: 138px;
}

.element .text-wrapper-46 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  position: relative;
  text-align: center;
  width: 290px;
}

.element .frame-33 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 656px;
  position: absolute;
  top: 138px;
}

.element .text-wrapper-47 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  position: relative;
  text-align: center;
  width: 233px;
}

.element .group-17 {
  height: 112px;
  left: 53px;
  position: absolute;
  top: 0;
  width: 776px;
}

.element .line-4 {
  height: 1px;
  left: 117px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 199px;
}

.element .line-5 {
  height: 1px;
  left: 460px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 199px;
}

.element .group-18 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 103px;
}

.element .overlap-group-8 {
  background: linear-gradient(180deg, rgb(25, 25, 25) 40.67%, rgb(16, 79, 85) 100%);
  border-radius: 50.5px;
  height: 101px;
  position: relative;
  width: 101px;
}

.element .text-wrapper-48 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  height: 57px;
  left: 26px;
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
  width: 41px;
}

.element .group-19 {
  height: 112px;
  left: 332px;
  position: absolute;
  top: 0;
  width: 114px;
}

.element .overlap-15 {
  background: linear-gradient(180deg, rgb(25, 25, 25) 40.67%, rgb(16, 79, 85) 100%);
  border-radius: 56px;
  height: 112px;
  position: relative;
  width: 112px;
}

.element .text-wrapper-49 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  height: 61px;
  left: 43px;
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
  width: 28px;
}

.element .group-20 {
  height: 101px;
  left: 675px;
  position: absolute;
  top: 5px;
  width: 103px;
}

.element .text-wrapper-50 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  height: 57px;
  left: 30px;
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
  width: 41px;
}

.element .frame-34 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 0%,
    rgb(13, 63, 68) 29.35%,
    rgb(145, 89, 108) 76.81%,
    rgb(249, 193, 192) 100%
  );
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 72px 0px;
  position: relative;
}

.element .content-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.element .works-only-with-the {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 665px;
}

.element .text-wrapper-51 {
  color: var(--neutral-0);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  position: relative;
  text-align: center;
  width: 711px;
}

.element .brokers {
  height: 211px;
  margin-bottom: -47px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  position: relative;
  width: 1234px;
}

.element .about-section {
  align-items: center;
  background-color: var(--neutral-100);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  margin-left: -96px;
  margin-right: -96px;
  padding: 64px 325px 80px;
  position: relative;
  width: 1440px;
}

.element .group-21 {
  height: 181px;
  margin-right: -3px;
  position: relative;
  width: 792px;
}

.element .text-wrapper-52 {
  color: var(--neutral-900);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  height: 55px;
  left: 310px;
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.element .text-wrapper-53 {
  color: var(--neutral-700);
  font-family: var(--subtitle-regular-font-family);
  font-size: var(--subtitle-regular-font-size);
  font-style: var(--subtitle-regular-font-style);
  font-weight: var(--subtitle-regular-font-weight);
  height: 102px;
  left: 0;
  letter-spacing: var(--subtitle-regular-letter-spacing);
  line-height: var(--subtitle-regular-line-height);
  position: absolute;
  text-align: center;
  top: 79px;
  width: 788px;
}

.element .frame-35 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  margin-left: -64px;
  margin-right: -64px;
  position: relative;
  width: 918px;
}

.element .text-wrapper-54 {
  color: var(--primary-03600);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.element .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.element .frame-38 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.element .DIPP-recognized {
  color: var(--neutral-900);
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25.2px;
  position: relative;
  text-align: center;
  width: 167px;
}

.element .image-5 {
  height: 60px;
  position: relative;
  width: 132px;
}

.element .image-6 {
  height: 49px;
  position: relative;
  width: 251px;
}

.element .image-7 {
  height: 64px;
  position: relative;
  width: 113px;
}

.element .website {
  height: 5544px;
  left: 0;
  position: absolute;
  top: 15461px;
  width: 1920px;
}

.element .group-22 {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 21386px;
  width: 1920px;
}

.element .overlap-16 {
  background-image: url(../../../static/img/mask-group.png);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo-5 {
  height: 32px;
  left: 1657px;
  position: absolute;
  top: 391px;
  width: 144px;
}

.element .frame-39 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 206px;
  position: absolute;
  top: 21101px;
}

.element .text-wrapper-55 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -0.75px;
  position: relative;
  width: fit-content;
}

.element .text-wrapper-56 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: normal;
  position: relative;
  width: 1507.98px;
}
