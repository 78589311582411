:root {
  --black-100: #191919;
  --headings-h2-font-family: "HKGrotesk-SemiBold", Helvetica;
  --headings-h2-font-size: 48px;
  --headings-h2-font-style: normal;
  --headings-h2-font-weight: 600;
  --headings-h2-letter-spacing: -.72px;
  --headings-h2-line-height: 120%;
  --headings-h3-font-family: "HKGrotesk-SemiBold", Helvetica;
  --headings-h3-font-size: 42px;
  --headings-h3-font-style: normal;
  --headings-h3-font-weight: 600;
  --headings-h3-letter-spacing: -.63px;
  --headings-h3-line-height: 132.0%;
  --headings-h5-font-family: "HKGrotesk-Bold", Helvetica;
  --headings-h5-font-size: 28px;
  --headings-h5-font-style: normal;
  --headings-h5-font-weight: 700;
  --headings-h5-letter-spacing: -.14px;
  --headings-h5-line-height: 132.0%;
  --headings-h6-font-family: "HKGrotesk-Bold", Helvetica;
  --headings-h6-font-size: 24px;
  --headings-h6-font-style: normal;
  --headings-h6-font-weight: 700;
  --headings-h6-letter-spacing: 0px;
  --headings-h6-line-height: 132.0%;
  --label-color-dark-primary: #fff;
  --label-color-light-primary: #000;
  --large-default-body-font-family: "SFPro Text-Regular", Helvetica;
  --large-default-body-font-size: 17px;
  --large-default-body-font-style: normal;
  --large-default-body-font-weight: 400;
  --large-default-body-letter-spacing: -.408px;
  --large-default-body-line-height: 22px;
  --large-default-headline-font-family: "SFPro Text-Semibold", Helvetica;
  --large-default-headline-font-size: 17px;
  --large-default-headline-font-style: normal;
  --large-default-headline-font-weight: 400;
  --large-default-headline-letter-spacing: -.408px;
  --large-default-headline-line-height: 22px;
  --neutral-0: #fff;
  --neutral-100: #f5f6f4;
  --neutral-500: #757575;
  --neutral-600: #5e5e5e;
  --neutral-700: #474747;
  --neutral-800: #303030;
  --neutral-900: #191919;
  --paragraph-regular-font-family: "Manrope-Medium", Helvetica;
  --paragraph-regular-font-size: 16px;
  --paragraph-regular-font-style: normal;
  --paragraph-regular-font-weight: 500;
  --paragraph-regular-letter-spacing: 0px;
  --paragraph-regular-line-height: 140.0%;
  --primary-01200: #faffe9;
  --primary-01600: #cefd1e;
  --primary-02-100: #e6fbf2;
  --primary-02600: #00d37f;
  --primary-03-200: #e7edee;
  --primary-03-900: #062022;
  --primary-03600: #104f55;
  --subtext-bold-font-family: "Manrope-Bold", Helvetica;
  --subtext-bold-font-size: 20px;
  --subtext-bold-font-style: normal;
  --subtext-bold-font-weight: 700;
  --subtext-bold-letter-spacing: 0px;
  --subtext-bold-line-height: 132.0%;
  --subtext-regular-font-family: "Manrope-Medium", Helvetica;
  --subtext-regular-font-size: 20px;
  --subtext-regular-font-style: normal;
  --subtext-regular-font-weight: 500;
  --subtext-regular-letter-spacing: 0px;
  --subtext-regular-line-height: 132.0%;
  --subtitle-bold-font-family: "Manrope-Bold", Helvetica;
  --subtitle-bold-font-size: 24px;
  --subtitle-bold-font-style: normal;
  --subtitle-bold-font-weight: 700;
  --subtitle-bold-letter-spacing: 0px;
  --subtitle-bold-line-height: 132.0%;
  --subtitle-regular-font-family: "Manrope-Medium", Helvetica;
  --subtitle-regular-font-size: 24px;
  --subtitle-regular-font-style: normal;
  --subtitle-regular-font-weight: 500;
  --subtitle-regular-letter-spacing: 0px;
  --subtitle-regular-line-height: 140.0%;
  --systemblue-light: #007aff;
  --title-medium-font-family: "HKGrotesk-SemiBold", Helvetica;
  --title-medium-font-size: 72px;
  --title-medium-font-style: normal;
  --title-medium-font-weight: 600;
  --title-medium-letter-spacing: -1.8px;
  --title-medium-line-height: 120%;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.logo.default {
  height: 40px;
}

.logo.small {
  width: 176px;
  height: 32px;
}

.logo.default.icon {
  width: 47px;
}

.logo.default.logotype {
  width: 220px;
}

.element {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div {
  background-color: #fff;
  width: 1920px;
  height: 21926px;
  position: relative;
}

.element .overlap {
  width: 1920px;
  height: 1326px;
  position: absolute;
  top: 7709px;
  left: 0;
}

.element .frame {
  background-color: #fff;
  width: 1920px;
  height: 1326px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.element .overlap-group-wrapper {
  border: 8.77px solid #dadada;
  border-radius: 23.38px;
  width: 1070px;
  height: 675px;
  position: absolute;
  top: 606px;
  left: -204px;
  overflow: hidden;
  box-shadow: -10px 2px 64px #0000001f;
}

.element .overlap-group {
  background: linear-gradient(#191919 0%, #104f55 100%);
  width: 1052px;
  height: 658px;
  position: relative;
}

.element .frame-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 46.76px;
  display: inline-flex;
  position: absolute;
  top: 159px;
  left: 315px;
}

.element .frame-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 35.07px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper {
  color: var(--neutral-0);
  letter-spacing: -.88px;
  text-align: center;
  width: 422.26px;
  margin-top: -.73px;
  font-family: Manrope, Helvetica;
  font-size: 58.4px;
  font-weight: 800;
  line-height: 64.3px;
  position: relative;
}

.element .so-why-only-few-make {
  flex: none;
  width: 345.82px;
  position: relative;
}

.element .CTA {
  all: unset;
  box-sizing: border-box;
  background: linear-gradient(#d9f551 0%, #00d37f 100%);
  border-radius: 2.92px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.31px;
  padding: 17.53px 29.22px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-2 {
  color: var(--neutral-900);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Manrope, Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  line-height: 23.1px;
  position: relative;
}

.element .header {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7.31px;
  width: 1052px;
  padding: 17.53px 75.98px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .logo-instance {
  left: unset !important;
  top: unset !important;
  width: 41.84px !important;
  height: 29.22px !important;
  position: relative !important;
}

.element .overlap-wrapper {
  border: 12px solid #dadada;
  border-radius: 32px;
  width: 1200px;
  height: 894px;
  position: absolute;
  top: 93px;
  left: 926px;
  overflow: hidden;
  box-shadow: -10px 2px 64px #0000001f;
}

.element .overlap-2 {
  background-color: #191919;
  width: 1176px;
  height: 870px;
  position: relative;
}

.element .frame-4 {
  flex-direction: column;
  align-items: center;
  gap: 39.2px;
  display: inline-flex;
  position: absolute;
  top: 568px;
  left: 261px;
}

.element .this-takes-years-to {
  flex: none;
  width: 620.34px;
  position: relative;
}

.element .div-wrapper {
  background-color: var(--primary-02600);
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8.17px;
  padding: 21.23px 32.67px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-3 {
  color: var(--neutral-0);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Manrope, Helvetica;
  font-size: 19.6px;
  font-weight: 700;
  line-height: 25.9px;
  position: relative;
}

.element .overlap-3 {
  border-radius: 3.27px;
  width: 190px;
  height: 244px;
  position: absolute;
  top: 265px;
  left: 492px;
}

.element .vector {
  width: 96px;
  height: 141px;
  position: absolute;
  top: 86px;
  left: 27px;
}

.element .rectangle {
  background: linear-gradient(#191919 0%, #104f55 100%);
  border-radius: 3.27px;
  width: 190px;
  height: 244px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group {
  width: 155px;
  height: 126px;
  position: absolute;
  top: 99px;
  left: 23px;
}

.element .efficient-investment {
  color: var(--neutral-0);
  letter-spacing: 0;
  width: 167px;
  height: 44px;
  font-family: Manrope, Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  line-height: 21.6px;
  position: absolute;
  top: 23px;
  left: 11px;
}

.element .logo-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8.17px;
  width: 1176px;
  padding: 19.6px 84.93px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .design-component-instance-node {
  left: unset !important;
  top: unset !important;
  width: 179.82px !important;
  height: 32.67px !important;
  position: relative !important;
}

.element .to-be-a-consistently {
  color: var(--neutral-100);
  letter-spacing: -1.47px;
  text-align: center;
  width: 760px;
  height: 118px;
  font-family: HK Grotesk-Regular, Helvetica;
  font-size: 49px;
  font-weight: 400;
  line-height: 58.8px;
  position: absolute;
  top: 110px;
  left: 225px;
}

.element .overlap-4 {
  background: url("mask-group-4.33b1aacb.png") 0 0 / 100% 100%;
  border-radius: 3.27px;
  width: 192px;
  height: 244px;
  position: absolute;
  top: 265px;
  left: 696px;
}

.element .text-wrapper-4 {
  color: var(--neutral-0);
  letter-spacing: 0;
  width: 147px;
  height: 44px;
  font-family: Manrope, Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  line-height: 21.6px;
  position: absolute;
  top: 23px;
  left: 22px;
}

.element .overlap-group-wrapper-2 {
  width: 191px;
  height: 244px;
  position: absolute;
  top: 265px;
  left: 288px;
}

.element .overlap-group-2 {
  border-radius: 4.9px;
  height: 244px;
  position: relative;
}

.element .rectangle-2 {
  background: linear-gradient(#191919 0%, #104f55 100%);
  border-radius: 4.9px;
  width: 191px;
  height: 244px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.element .unwavering-trading {
  color: var(--neutral-0);
  letter-spacing: 0;
  width: 158px;
  height: 44px;
  font-family: Manrope, Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  line-height: 21.6px;
  position: absolute;
  top: 22px;
  left: 20px;
}

.element .mask-group {
  width: 191px;
  height: 189px;
  position: absolute;
  top: 55px;
  left: 0;
}

.element .img {
  width: 191px;
  height: 244px;
  position: absolute;
  top: 266px;
  left: 84px;
}

.element .div-2 {
  width: 190px;
  height: 244px;
  position: absolute;
  top: 265px;
  left: 901px;
}

.element .overlap-5 {
  width: 171px;
  height: 244px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-3 {
  width: 81px;
  height: 244px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-5 {
  color: var(--neutral-0);
  letter-spacing: 0;
  width: 151px;
  height: 44px;
  font-family: Manrope, Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  line-height: 21.6px;
  position: absolute;
  top: 22px;
  left: 20px;
}

.element .mask-group-2 {
  width: 81px;
  height: 167px;
  position: absolute;
  top: 77px;
  left: 0;
}

.element .group-2 {
  width: 46px;
  height: 26px;
  position: absolute;
  top: 176px;
  left: 12px;
}

.element .group-3 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: -6462px;
  left: -1699px;
}

.element .seamlessly {
  color: #000;
  letter-spacing: -1.12px;
  width: 703px;
  height: 140px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 56px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 120px;
  left: 120px;
}

.element .growth-user-centric {
  color: #000;
  letter-spacing: 0;
  width: 663px;
  height: 136px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 309px;
  left: 120px;
}

.element .frame-5 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 120px;
  left: 1584px;
}

.element .text-wrapper-6 {
  color: #484848;
  letter-spacing: -.39px;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-7 {
  color: #484848;
  letter-spacing: -.39px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .frame-6 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 120px;
  left: 120px;
}

.element .text-wrapper-8 {
  color: #000;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .intelligent {
  color: #2f2f2f;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 96px;
  font-weight: 400;
  line-height: 112px;
  position: relative;
}

.element .group-4 {
  width: 350px;
  height: 120px;
  position: absolute;
  top: 463px;
  left: 120px;
}

.element .frame-7 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.element .UIX {
  width: 183px;
  height: 56px;
  position: relative;
}

.element .frame-wrapper {
  background-color: #000;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.element .frame-8 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .group-5 {
  background-color: #f8f8f8;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.element .overlap-group-3 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.element .line {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.element .line-2 {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .text-wrapper-9 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.element .UIX-2 {
  width: 149px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-10 {
  color: #5c45cc;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .hero {
  width: 1920px;
  height: 840px;
  position: absolute;
  top: 653px;
  left: 0;
  overflow: hidden;
}

.element .element-wrapper {
  background-color: #00d37f;
  height: 888px;
  position: relative;
}

.element .img-2 {
  width: 936px;
  height: 784px;
  position: absolute;
  top: 56px;
  left: 492px;
}

.element .the-challenge {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1920px;
  padding: 64px 0 80px;
  display: flex;
  position: absolute;
  top: 1493px;
  left: 0;
}

.element .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: relative;
}

.element .frame-10 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-11 {
  color: #2f2f2f;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .let-s-face-it-many {
  color: #676768;
  letter-spacing: -.24px;
  width: 1507.98px;
  font-family: Neue Haas Grotesk Text Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.element .span {
  color: #676768;
  letter-spacing: -.24px;
  font-family: Neue Haas Grotesk Text Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
}

.element .text-wrapper-12 {
  font-family: Neue Haas Grotesk Text Pro-Bold, Helvetica;
  font-weight: 700;
}

.element .frame-11 {
  background-color: #00d37f;
  border-radius: 12px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 32px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-13 {
  color: #fff;
  letter-spacing: -.24px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
  position: relative;
}

.element .headshot {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 2361px;
  left: 0;
}

.element .branding {
  background-color: #fff;
  width: 1920px;
  height: 2247px;
  position: absolute;
  top: 3441px;
  left: 0;
}

.element .overlap-6 {
  background-color: #fff;
  height: 2247px;
  position: relative;
}

.element .text-wrapper-14 {
  color: #191919;
  letter-spacing: -.24px;
  height: 70px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 56px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 110px;
  left: 119px;
}

.element .group-wrapper {
  background-color: #104f55;
  border-radius: 24px;
  width: 663px;
  height: 882px;
  position: absolute;
  top: 252px;
  left: 120px;
  overflow: hidden;
}

.element .group-6 {
  width: 319px;
  height: 274px;
  position: absolute;
  top: 304px;
  left: 173px;
}

.element .frame-12 {
  border-radius: 24px;
  width: 567px;
  height: 651px;
  position: absolute;
  top: 1359px;
  left: 1233px;
  overflow: hidden;
}

.element .overlap-7 {
  width: 760px;
  height: 1681px;
  position: relative;
  top: -799px;
}

.element .rectangle-4 {
  background-color: #d0b6fc;
  width: 663px;
  height: 882px;
  position: absolute;
  top: 799px;
  left: 0;
}

.element .mockup {
  width: 648px;
  height: 1298px;
  position: absolute;
  top: 0;
  left: 112px;
  box-shadow: 0 72.17px 84.19px #9393935e;
}

.element .overlap-8 {
  height: 1298px;
  position: relative;
}

.element .body-space-gray {
  width: 648px;
  height: 1298px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .button-space-gray {
  width: 6px;
  height: 51px;
  position: absolute;
  top: 174px;
  left: 0;
  overflow: hidden;
}

.element .rectangle-5 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 2px;
  height: 47px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.75px 0 .75px #00000026;
}

.element .union {
  width: 6px;
  height: 51px;
  position: absolute;
  top: -2639px;
  left: -1319px;
}

.element .button-space-gray-2 {
  width: 6px;
  height: 149px;
  position: absolute;
  top: 298px;
  left: 642px;
  overflow: hidden;
  transform: rotate(180deg);
}

.element .rectangle-6 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 2px;
  height: 144px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.75px 0 .75px #00000026;
}

.element .union-2 {
  width: 6px;
  height: 149px;
  position: absolute;
  top: 2763px;
  left: 1961px;
  transform: rotate(-180deg);
}

.element .button-space-gray-3 {
  width: 6px;
  height: 92px;
  position: absolute;
  top: 268px;
  left: 0;
  overflow: hidden;
}

.element .rectangle-7 {
  background: linear-gradient(#fff0 0%, #fff3 100%);
  width: 2px;
  height: 87px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset -.75px 0 .75px #00000026;
}

.element .union-3 {
  width: 6px;
  height: 92px;
  position: absolute;
  top: -2733px;
  left: -1319px;
}

.element .button-space-gray-4 {
  width: 6px;
  height: 92px;
  position: absolute;
  top: 389px;
  left: 0;
  overflow: hidden;
}

.element .union-4 {
  width: 6px;
  height: 92px;
  position: absolute;
  top: -2854px;
  left: -1319px;
}

.element .overlap-group-4 {
  background-image: url("frame.e67ad808.svg");
  background-size: 100% 100%;
  width: 450px;
  height: 499px;
  position: absolute;
  top: 799px;
  left: 5px;
}

.element .shine {
  width: 450px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .outer-ring {
  width: 450px;
  height: 498px;
  position: absolute;
  top: 0;
  left: 1px;
}

.element .bands {
  width: 450px;
  height: 357px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .base {
  width: 436px;
  height: 484px;
  position: absolute;
  top: 799px;
  left: 19px;
}

.element .display {
  width: 609px;
  height: 1267px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.element .mask-group-3 {
  width: 413px;
  height: 459px;
  position: absolute;
  top: 784px;
  left: 23px;
}

.element .img-wrapper {
  background-image: url("rectangle-677.21703827.svg");
  background-size: 100% 100%;
  border-radius: 24px;
  width: 961px;
  height: 916px;
  position: absolute;
  top: 374px;
  left: 839px;
  overflow: hidden;
}

.element .frame-13 {
  width: 765px;
  height: 168px;
  position: absolute;
  top: 374px;
  left: 98px;
}

.element .p {
  color: #282828;
  letter-spacing: -.16px;
  text-align: center;
  width: 555px;
  height: 135px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  position: absolute;
  top: 1165px;
  left: 168px;
}

.element .text-wrapper-15 {
  color: #282828;
  letter-spacing: -.16px;
  text-align: center;
  width: 855px;
  height: 90px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  position: absolute;
  top: 2046px;
  left: 229px;
}

.element .versatile-responsive {
  color: #282828;
  letter-spacing: -.16px;
  text-align: center;
  width: 483px;
  height: 90px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  position: absolute;
  top: 2046px;
  left: 1275px;
}

.element .text-wrapper-16 {
  color: #282828;
  letter-spacing: -.16px;
  text-align: center;
  width: 824px;
  height: 90px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  position: absolute;
  top: 251px;
  left: 909px;
}

.element .frame-14 {
  border-radius: 24px;
  width: 1057px;
  height: 651px;
  position: absolute;
  top: 1359px;
  left: 120px;
}

.element .overlap-9 {
  background-color: #f4fffa;
  border-radius: 24px;
  width: 1058px;
  height: 651px;
  position: relative;
}

.element .frame-15 {
  width: 768px;
  height: 527px;
  position: absolute;
  top: 62px;
  left: 153px;
}

.element .overlap-wrapper-2 {
  width: 1920px;
  height: 941px;
  position: absolute;
  top: 5688px;
  left: 0;
  overflow: hidden;
}

.element .overlap-10 {
  background-color: #191919;
  height: 1080px;
  position: relative;
}

.element .rectangle-8 {
  background-color: #00d37f;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .mackbooks {
  width: 1920px;
  height: 941px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-9 {
  background-color: #fff;
  width: 136px;
  height: 27px;
  position: absolute;
  top: 135px;
  left: 1019px;
  transform: rotate(13.52deg);
}

.element .group-7 {
  width: 119px;
  height: 48px;
  position: absolute;
  top: 124px;
  left: 1029px;
}

.element .overlap-wrapper-3 {
  width: 1113px;
  height: 1080px;
  position: absolute;
  top: 6629px;
  left: 0;
  overflow: hidden;
}

.element .overlap-11 {
  background-color: #191919;
  width: 1920px;
  height: 1080px;
  position: relative;
}

.element .overlap-group-wrapper-3 {
  width: 1113px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.element .overlap-group-5 {
  background-color: #ef6461;
  height: 1663px;
  position: relative;
}

.element .frame-16 {
  width: 988px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 125px;
}

.element .overlap-wrapper-4 {
  width: 807px;
  height: 732px;
  position: absolute;
  top: 6629px;
  left: 1113px;
  overflow: hidden;
}

.element .overlap-12 {
  width: 1920px;
  height: 1082px;
  position: relative;
  left: -1034px;
}

.element .rectangle-10 {
  background-color: #191919;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 2px;
  left: 0;
}

.element .rectangle-11 {
  background-color: #d9f551;
  width: 807px;
  height: 732px;
  position: absolute;
  top: 0;
  left: 1034px;
}

.element .img-3 {
  width: 740px;
  height: 732px;
  position: absolute;
  top: 0;
  left: 1101px;
}

.element .basic-office {
  object-fit: cover;
  width: 1px;
  height: 730px;
  position: absolute;
  top: 2px;
  left: 1034px;
}

.element .ios-notification-wrapper {
  background-color: #1e2ede;
  width: 806px;
  height: 348px;
  position: absolute;
  top: 7361px;
  left: 1113px;
}

.element .ios-notification {
  background-color: #fff;
  border-radius: 13.94px;
  flex-direction: column;
  align-items: center;
  gap: 13.94px;
  padding: 20.91px 13.94px;
  display: inline-flex;
  position: relative;
  top: 82px;
  left: 94px;
  box-shadow: 0 4px 96px #82828229;
}

.element .header-2 {
  width: 590.83px;
  height: 31.37px;
  position: relative;
}

.element .overlap-13 {
  width: 595px;
  height: 31px;
  position: relative;
}

.element .header-3 {
  width: 595px;
  height: 31px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-group-6 {
  background-color: #757575;
  border-radius: 6.97px;
  width: 31px;
  height: 31px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-8 {
  background-color: #191919;
  border-radius: 6.97px;
  height: 31px;
}

.element .text-wrapper-17 {
  color: #1d1d1d;
  letter-spacing: 0;
  white-space: nowrap;
  width: 319px;
  height: 21px;
  font-family: Helvetica-Regular, Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 44px;
}

.element .text-wrapper-18 {
  color: #1d1d1d;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 204px;
  height: 21px;
  font-family: Helvetica-Regular, Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 387px;
}

.element .logo-2 {
  width: 24px;
  height: 19px;
  position: absolute;
  top: 5px;
  left: 3px;
}

.element .meta-data {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20.91px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .title-desc-pic {
  flex: none;
  align-items: center;
  gap: 26.14px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .title-desc {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 6.97px;
  display: inline-flex;
  position: relative;
}

.element .div-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17.43px;
  width: 508.91px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .title {
  color: #1d1d1d;
  letter-spacing: 0;
  width: 508.91px;
  margin-top: -1.74px;
  font-family: Helvetica-Bold, Helvetica;
  font-size: 20.9px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .description {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17.43px;
  width: 508.91px;
  display: flex;
  position: relative;
}

.element .frame-17 {
  flex: none;
  align-items: flex-start;
  gap: 6.97px;
  display: inline-flex;
  position: relative;
}

.element .description-2 {
  color: #1d1d1d;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.74px;
  font-family: Helvetica-Regular, Helvetica;
  font-size: 20.9px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .frame-18 {
  flex: none;
  align-items: center;
  gap: 6.97px;
  display: inline-flex;
  position: relative;
}

.element .image {
  width: 17.43px;
  height: 12.07px;
  position: relative;
}

.element .roadie {
  border-radius: 6.97px;
  width: 55.77px;
  height: 55.77px;
  position: relative;
}

.element .text-wrapper-19 {
  color: #b1b1b1;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1.74px;
  font-family: Helvetica-Regular, Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .frame-19 {
  justify-content: space-between;
  align-items: center;
  width: 1680px;
  display: flex;
  position: absolute;
  top: 9099px;
  left: 120px;
}

.element .text-wrapper-20 {
  color: #000;
  letter-spacing: -1.12px;
  width: 582px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 56px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .we-focused-on-every {
  color: #000;
  letter-spacing: -.64px;
  width: 861px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.element .web-wrapper {
  background-color: #e6fbf2;
  width: 1920px;
  height: 6158px;
  position: absolute;
  top: 9303px;
  left: 0;
}

.element .web {
  border: 12px solid #104f55;
  border-radius: 32px;
  width: 1464px;
  height: 5910px;
  position: relative;
  top: 117px;
  left: 228px;
  overflow: hidden;
}

.element .overlap-14 {
  width: 1440px;
  height: 3112px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .BG {
  background-color: #faffe9;
  width: 1440px;
  height: 843px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-9 {
  width: 1440px;
  height: 2955px;
  position: absolute;
  top: 157px;
  left: 0;
}

.element .logo-instance-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 1440px;
  padding: 24px 104px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .logo-3 {
  left: unset !important;
  top: unset !important;
  width: 220.19px !important;
  position: relative !important;
}

.element .context {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: inline-flex;
  position: absolute;
  top: 124px;
  left: 400px;
}

.element .title-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-21 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  font-family: var(--title-medium-font-family);
  font-size: var(--title-medium-font-size);
  font-style: var(--title-medium-font-style);
  font-weight: var(--title-medium-font-weight);
  letter-spacing: var(--title-medium-letter-spacing);
  line-height: var(--title-medium-line-height);
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#191919 40.67%, #104f55 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
  -webkit-background-clip: text !important;
}

.element .text-wrapper-22 {
  color: var(--neutral-600);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  text-align: center;
  width: 639px;
  position: relative;
}

.element .CTA-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 526px;
  display: flex;
  position: relative;
}

.element .button {
  all: unset;
  background-color: var(--primary-01600);
  box-sizing: border-box;
  width: 275px;
  height: 74px;
  position: relative;
  box-shadow: 0 22px 63px #d2d2d208;
}

.element .text-wrapper-23 {
  color: var(--neutral-900);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  white-space: nowrap;
  height: 26px;
  position: absolute;
  top: 23px;
  left: 32px;
}

.element .coming-soon-on-web {
  color: #0000;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.element .text-wrapper-24 {
  color: #8c8c8c;
}

.element .text-wrapper-25 {
  color: #5c45cc;
}

.element .hero-image {
  width: 1351px;
  height: 978px;
  position: absolute;
  top: 390px;
  left: 44px;
}

.element .feature {
  width: 1159px;
  height: 572px;
  position: absolute;
  top: 1823px;
  left: 104px;
}

.element .image-2 {
  width: 599px;
  height: 572px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .context-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 471px;
  display: flex;
  position: absolute;
  top: 116px;
  left: 688px;
  overflow: hidden;
}

.element .text-wrapper-26 {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  width: 493px;
  margin-top: -1px;
  margin-right: -22px;
  position: relative;
}

.element .text-wrapper-27 {
  color: var(--primary-03-200);
  letter-spacing: 0;
  width: 471px;
  font-family: Manrope, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  position: relative;
}

.element .feature-2 {
  align-items: center;
  gap: 73px;
  display: inline-flex;
  position: absolute;
  top: 1179px;
  left: 180px;
}

.element .content {
  width: 421px;
  height: 352px;
  position: relative;
}

.element .CTA-3 {
  all: unset;
  background-color: var(--primary-01600);
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 184px;
  height: 64px;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 288px;
  left: 0;
  overflow: hidden;
  box-shadow: 0 22px 63px #d2d2d208;
}

.element .text-wrapper-28 {
  color: var(--primary-03-900);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .content-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 421px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-29 {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-30 {
  color: var(--primary-03-200);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  width: 367px;
  position: relative;
}

.element .image-3 {
  width: 708.52px;
  height: 594.24px;
  margin-top: -22.24px;
  margin-right: -46.52px;
  position: relative;
}

.element .group-10 {
  width: 1336px;
  height: 572px;
  position: absolute;
  top: 2467px;
  left: 104px;
}

.element .content-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 471px;
  display: flex;
  position: absolute;
  top: 156px;
  left: 0;
  overflow: hidden;
}

.element .efficient-investing {
  color: var(--primary-03-200);
  font-family: var(--headings-h2-font-family);
  font-size: var(--headings-h2-font-size);
  font-style: var(--headings-h2-font-style);
  font-weight: var(--headings-h2-font-weight);
  letter-spacing: var(--headings-h2-letter-spacing);
  line-height: var(--headings-h2-line-height);
  width: 476px;
  margin-top: -1px;
  margin-right: -5px;
  position: relative;
}

.element .image-4 {
  width: 752px;
  height: 572px;
  position: absolute;
  top: 0;
  left: 584px;
}

.element .frame-20 {
  background-color: var(--primary-01600);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 1440px;
  padding: 64px 96px;
  display: flex;
  position: absolute;
  top: 3112px;
  left: 0;
}

.element .frame-21 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-31 {
  color: #000;
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .simulated {
  background-color: #fff;
  border-radius: 8px;
  width: 389px;
  height: 454px;
  position: relative;
  box-shadow: 21px 36px 82px #90b1148f;
}

.element .frame-23 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 34px;
  left: 24px;
}

.element .text-wrapper-32 {
  color: var(--neutral-800);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  text-align: center;
  width: 341px;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-33 {
  color: var(--neutral-700);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  text-align: center;
  width: 341px;
  position: relative;
}

.element .frame-24 {
  width: 341px;
  height: 234px;
  position: absolute;
  top: 197px;
  left: 24px;
}

.element .assisted {
  background-color: var(--primary-02-100);
  border-radius: 4px;
  width: 389px;
  height: 454px;
  position: relative;
}

.element .overlap-group-7 {
  background-color: #fff;
  border-radius: 12px;
  height: 454px;
  position: relative;
}

.element .text-wrapper-34 {
  color: var(--neutral-900);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  text-align: center;
  width: 349px;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-35 {
  color: var(--neutral-900);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  text-align: center;
  width: 296px;
  position: relative;
}

.element .automatic {
  background-color: #fff;
  border-radius: 12px;
  width: 389px;
  height: 454px;
  position: relative;
}

.element .frame-25 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 341px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 24px;
  overflow: hidden;
}

.element .text-wrapper-36 {
  color: var(--neutral-900);
  font-family: var(--headings-h5-font-family);
  font-size: var(--headings-h5-font-size);
  font-style: var(--headings-h5-font-style);
  font-weight: var(--headings-h5-font-weight);
  letter-spacing: var(--headings-h5-letter-spacing);
  line-height: var(--headings-h5-line-height);
  text-align: center;
  width: 349px;
  margin-top: -1px;
  margin-right: -8px;
  position: relative;
}

.element .text-wrapper-37 {
  color: var(--neutral-900);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  text-align: center;
  width: 341px;
  position: relative;
}

.element .group-11 {
  width: 1440px;
  height: 400px;
  position: absolute;
  top: 5486px;
  left: 0;
}

.element .frame-26 {
  background-color: var(--neutral-900);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 1440px;
  padding: 48px 96px;
  display: flex;
  position: relative;
}

.element .group-12 {
  width: 1235.06px;
  height: 304px;
  position: relative;
}

.element .group-13 {
  width: 605px;
  height: 114px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-27 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 605px;
  display: flex;
  position: relative;
}

.element .text-wrapper-38 {
  color: var(--neutral-0);
  font-family: var(--subtitle-bold-font-family);
  font-size: var(--subtitle-bold-font-size);
  font-style: var(--subtitle-bold-font-style);
  font-weight: var(--subtitle-bold-font-weight);
  letter-spacing: var(--subtitle-bold-letter-spacing);
  line-height: var(--subtitle-bold-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .apply-for-all-access {
  width: 615px;
  height: 66px;
  margin-right: -10.42px;
  position: relative;
  overflow: hidden;
}

.element .CTA-4 {
  border: 1px solid;
  border-color: var(--neutral-500);
  width: 411px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 22px 63px #d2d2d208;
}

.element .join {
  background-color: var(--primary-02600);
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 24px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 411px;
}

.element .text-wrapper-39 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-40 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  white-space: nowrap;
  height: 26px;
  position: absolute;
  top: 78px;
  left: 32px;
}

.element .frame-28 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 245px;
  display: flex;
  position: absolute;
  top: 154px;
  left: 0;
}

.element .group-14 {
  width: 184px;
  height: 26px;
  position: relative;
}

.element .text-wrapper-41 {
  color: var(--neutral-0);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  text-align: center;
  white-space: nowrap;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .support-moneyfactory-wrapper {
  width: 247px;
  height: 26px;
  margin-right: -1.61px;
  position: relative;
}

.element .support-moneyfactory {
  width: 242px;
  height: 20px;
  display: block;
  position: absolute;
  top: 6px;
  left: 1px;
}

.element .line-3 {
  object-fit: cover;
  width: 1233px;
  height: 1px;
  position: absolute;
  top: 265px;
  left: 0;
}

.element .frame-29 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 376px;
  height: 220px;
  display: flex;
  position: absolute;
  top: 0;
  left: 856px;
}

.element .logo-4 {
  left: unset !important;
  top: unset !important;
  width: 244.21px !important;
  height: 44.36px !important;
  margin-right: -.21px !important;
  position: relative !important;
}

.element .finaptis-consulting {
  color: var(--neutral-0);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  text-align: right;
  width: 375px;
  position: relative;
}

.element .instagram-linkedin {
  color: var(--neutral-0);
  font-family: var(--paragraph-regular-font-family);
  font-size: var(--paragraph-regular-font-size);
  font-style: var(--paragraph-regular-font-style);
  font-weight: var(--paragraph-regular-font-weight);
  letter-spacing: var(--paragraph-regular-letter-spacing);
  line-height: var(--paragraph-regular-line-height);
  width: 326px;
  height: 22px;
  position: absolute;
  top: 282px;
  left: 0;
}

.element .section {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 1440px;
  padding: 72px 96px 0;
  display: flex;
  position: absolute;
  top: 3789px;
  left: 0;
}

.element .group-15 {
  width: 889px;
  height: 436px;
  position: relative;
}

.element .frame-30 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 86px;
}

.element .text-wrapper-42 {
  color: var(--neutral-900);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-43 {
  color: var(--neutral-900);
  font-family: var(--subtitle-regular-font-family);
  font-size: var(--subtitle-regular-font-size);
  font-style: var(--subtitle-regular-font-style);
  font-weight: var(--subtitle-regular-font-weight);
  letter-spacing: var(--subtitle-regular-letter-spacing);
  line-height: var(--subtitle-regular-line-height);
  text-align: center;
  width: 747px;
  position: relative;
}

.element .group-16 {
  width: 889px;
  height: 264px;
  position: absolute;
  top: 172px;
  left: 0;
}

.element .frame-31 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 138px;
  left: 0;
}

.element .text-wrapper-44 {
  color: var(--neutral-900);
  font-family: var(--headings-h6-font-family);
  font-size: var(--headings-h6-font-size);
  font-style: var(--headings-h6-font-style);
  font-weight: var(--headings-h6-font-weight);
  letter-spacing: var(--headings-h6-letter-spacing);
  line-height: var(--headings-h6-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-45 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  text-align: center;
  width: 207px;
  position: relative;
}

.element .frame-32 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 138px;
  left: 298px;
}

.element .text-wrapper-46 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  text-align: center;
  width: 290px;
  position: relative;
}

.element .frame-33 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 138px;
  left: 656px;
}

.element .text-wrapper-47 {
  color: var(--neutral-900);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  text-align: center;
  width: 233px;
  position: relative;
}

.element .group-17 {
  width: 776px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 53px;
}

.element .line-4 {
  object-fit: cover;
  width: 199px;
  height: 1px;
  position: absolute;
  top: 55px;
  left: 117px;
}

.element .line-5 {
  object-fit: cover;
  width: 199px;
  height: 1px;
  position: absolute;
  top: 55px;
  left: 460px;
}

.element .group-18 {
  width: 103px;
  height: 101px;
  position: absolute;
  top: 5px;
  left: 0;
}

.element .overlap-group-8 {
  background: linear-gradient(#191919 40.67%, #104f55 100%);
  border-radius: 50.5px;
  width: 101px;
  height: 101px;
  position: relative;
}

.element .text-wrapper-48 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  width: 41px;
  height: 57px;
  position: absolute;
  top: 22px;
  left: 26px;
}

.element .group-19 {
  width: 114px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 332px;
}

.element .overlap-15 {
  background: linear-gradient(#191919 40.67%, #104f55 100%);
  border-radius: 56px;
  width: 112px;
  height: 112px;
  position: relative;
}

.element .text-wrapper-49 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  width: 28px;
  height: 61px;
  position: absolute;
  top: 27px;
  left: 43px;
}

.element .group-20 {
  width: 103px;
  height: 101px;
  position: absolute;
  top: 5px;
  left: 675px;
}

.element .text-wrapper-50 {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  width: 41px;
  height: 57px;
  position: absolute;
  top: 22px;
  left: 30px;
}

.element .frame-34 {
  background: linear-gradient(#000 0%, #0d3f44 29.35%, #91596c 76.81%, #f9c1c0 100%);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 72px 0;
  display: inline-flex;
  position: relative;
}

.element .content-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .works-only-with-the {
  color: var(--neutral-0);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  width: 665px;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-51 {
  color: var(--neutral-0);
  font-family: var(--subtext-regular-font-family);
  font-size: var(--subtext-regular-font-size);
  font-style: var(--subtext-regular-font-style);
  font-weight: var(--subtext-regular-font-weight);
  letter-spacing: var(--subtext-regular-letter-spacing);
  line-height: var(--subtext-regular-line-height);
  text-align: center;
  width: 711px;
  position: relative;
}

.element .brokers {
  width: 1234px;
  height: 211px;
  margin-bottom: -47px;
  margin-left: -.5px;
  margin-right: -.5px;
  position: relative;
}

.element .about-section {
  background-color: var(--neutral-100);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 1440px;
  margin-left: -96px;
  margin-right: -96px;
  padding: 64px 325px 80px;
  display: flex;
  position: relative;
}

.element .group-21 {
  width: 792px;
  height: 181px;
  margin-right: -3px;
  position: relative;
}

.element .text-wrapper-52 {
  color: var(--neutral-900);
  font-family: var(--headings-h3-font-family);
  font-size: var(--headings-h3-font-size);
  font-style: var(--headings-h3-font-style);
  font-weight: var(--headings-h3-font-weight);
  letter-spacing: var(--headings-h3-letter-spacing);
  line-height: var(--headings-h3-line-height);
  text-align: center;
  white-space: nowrap;
  height: 55px;
  position: absolute;
  top: 0;
  left: 310px;
}

.element .text-wrapper-53 {
  color: var(--neutral-700);
  font-family: var(--subtitle-regular-font-family);
  font-size: var(--subtitle-regular-font-size);
  font-style: var(--subtitle-regular-font-style);
  font-weight: var(--subtitle-regular-font-weight);
  letter-spacing: var(--subtitle-regular-letter-spacing);
  line-height: var(--subtitle-regular-line-height);
  text-align: center;
  width: 788px;
  height: 102px;
  position: absolute;
  top: 79px;
  left: 0;
}

.element .frame-35 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  width: 918px;
  margin-left: -64px;
  margin-right: -64px;
  display: flex;
  position: relative;
}

.element .text-wrapper-54 {
  color: var(--primary-03600);
  font-family: var(--subtext-bold-font-family);
  font-size: var(--subtext-bold-font-size);
  font-style: var(--subtext-bold-font-style);
  font-weight: var(--subtext-bold-font-weight);
  letter-spacing: var(--subtext-bold-letter-spacing);
  line-height: var(--subtext-bold-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-36 {
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .frame-37 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.element .frame-38 {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .DIPP-recognized {
  color: var(--neutral-900);
  letter-spacing: 0;
  text-align: center;
  width: 167px;
  font-family: Manrope, Helvetica;
  font-size: 18px;
  font-weight: 800;
  line-height: 25.2px;
  position: relative;
}

.element .image-5 {
  width: 132px;
  height: 60px;
  position: relative;
}

.element .image-6 {
  width: 251px;
  height: 49px;
  position: relative;
}

.element .image-7 {
  width: 113px;
  height: 64px;
  position: relative;
}

.element .website {
  width: 1920px;
  height: 5544px;
  position: absolute;
  top: 15461px;
  left: 0;
}

.element .group-22 {
  background-image: url("rectangle-122.ee52b271.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 21386px;
  left: 0;
}

.element .overlap-16 {
  background-image: url("mask-group.577c4adf.png");
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo-5 {
  width: 144px;
  height: 32px;
  position: absolute;
  top: 391px;
  left: 1657px;
}

.element .frame-39 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 21101px;
  left: 206px;
}

.element .text-wrapper-55 {
  color: #000;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-56 {
  color: #000;
  letter-spacing: -.24px;
  width: 1507.98px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

/*# sourceMappingURL=index.aea268d8.css.map */
